import { getNodeLabel } from '@ory/integrations/ui';
import Image from 'next/image';
import microsoftLogo from '../../assets/microsoft-small.svg';
import { LargePrimaryButton, LargeSecondaryButton } from 'ui';

import { NodeInputProps } from './helpers';

export function NodeInputSubmit({
  node,
  attributes,
  setValue,
  disabled,
  dispatchSubmit,
}: NodeInputProps) {
  const isProvider = attributes.name === 'provider';
  const microsoft = isProvider && attributes.value === 'microsoft';

  const Button = isProvider ? LargeSecondaryButton : LargePrimaryButton;

  return (
    <Button
      className='mb-3'
      name={attributes.name}
      value={attributes.value || ''}
      onClick={(e) => setValue(attributes.value).then(() => dispatchSubmit(e))}
      disabled={attributes.disabled || disabled}
    >
      {microsoft && (
        <span className="flex mr-3">
          <Image src={microsoftLogo} alt="microsoft" />
        </span>
      )}
      {getNodeLabel(node)}
    </Button>
  );
}

import { NodeInputProps } from './helpers'
import { GreyTextInput } from 'ui'

export function NodeInputText(props: NodeInputProps) {
  const { node, attributes, value = '', setValue, disabled } = props

  // Render a generic text input field.
  return (
    <GreyTextInput
      className='mb-3'
      placeholder={node.meta.label?.text}
      onChange={(e) => {
        setValue(e.target.value)
      }}
      type={attributes.type as ("number" | "text" | "password" | "email" | "tel" | "url")}
      name={attributes.name}
      value={value}
      disabled={attributes.disabled || disabled}
      // help={node.messages.length > 0} TODO: add help/info? what is it?
      // state={
      //   node.messages.find(({ type }) => type === 'error') ? 'error' : undefined
      // }
      // subtitle={
      //   <>
      //     {node.messages.map(({ text, id }, k) => (
      //       <span key={`${id}-${k}`} data-testid={`ui/message/${id}`}>
      //         {text}
      //       </span>
      //     ))}
      //   </>
      // }
    />
  )
}
